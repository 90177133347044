<template>
    <ts-loading-banner :loading="loading">
        <div class="tw-space-y-2">
            <div class="row">
                <div
                    class="col-md-6 tw-space-y-1"
                    v-if="$whois.admin()"
                >
                    <label class="required">{{
                        $t("bookingPickup.shopName")
                    }}</label>
                    <a-select
                        v-model="model.shop_id"
                        :options="shopList"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                        @change="onShopChange"
                        class="tw-w-full"
                        :placeholder="$t('bookingPickup.shopName')"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('shop_id')"
                    >
                        {{ errors.first("shop_id") }}
                    </div>
                </div>
                <div
                    class="tw-space-y-1"
                    :class="{ 'col-md-6': $whois.admin() }"
                >
                    <label class="required">{{
                        $t("bookingPickup.serviceType")
                    }}</label>
                    <a-select
                        v-model="model.service_type_id"
                        :options="serviceType"
                        class="tw-w-full"
                        :placeholder="$t('bookingPickup.serviceType')"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('service_type_id')"
                    >
                        {{ errors.first("service_type_id") }}
                    </div>
                </div>
            </div>
            <div class="row" v-if="$whois.admin()">
                <div class="col-md-6 tw-space-y-1">
                    <label>{{ $t("bookingPickup.driverName") }}</label>
                    <a-select
                        v-model="model.driver_id"
                        :options="driverList"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                        @change="onDriverChange"
                        class="tw-w-full"
                        :placeholder="$t('bookingPickup.driverName')"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('driver_id')"
                    >
                        {{ errors.first("driver_id") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label>{{ $t("bookingPickup.vehicleType") }}</label>
                    <a-select
                        v-model="model.vehicle_type_id"
                        :options="vehicleTypeList"
                        class="tw-w-full"
                        :placeholder="$t('bookingPickup.vehicleType')"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('vehicle_type_id')"
                    >
                        {{ errors.first("vehicle_type_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("bookingPickup.receiverPhone")
                    }}</label>
                    <input
                        v-model.trim="model.receiver_phone"
                        type="text"
                        class="form-control"
                        :placeholder="$t('bookingPickup.receiverPhone')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('receiver_phone')"
                    >
                        {{ errors.first("receiver_phone") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("bookingPickup.paidBy")
                    }}</label>
                    <a-select
                        v-model.number="model.fee_paid_by"
                        :placeholder="$t('bookingPickup.paidBy')"
                        :options="[
                            { value: 1, label: 'Buyer' },
                            { value: 2, label: 'Shop' }
                        ]"
                        class="tw-w-full"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('fee_paid_by')"
                    >
                        {{ errors.first("fee_paid_by") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("bookingPickup.packageType")
                    }}</label>
                    <a-select
                        v-model.number="model.package_type_id"
                        :options="packageTypeList"
                        class="tw-w-full"
                        :placeholder="$t('bookingPickup.packageType')"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('package_type_id')"
                    >
                        {{ errors.first("package_type_id") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("bookingPickup.zone")
                    }}</label>
                    <a-select
                        v-model.number="model.zone_id"
                        :options="zoneList"
                        class="tw-w-full"
                        :placeholder="$t('bookingPickup.zone')"
                    ></a-select>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('zone_id')"
                    >
                        {{ errors.first("zone_id") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("bookingPickup.price")
                    }}</label>
                    <input
                        v-model.number="model.purchase_amount"
                        type="number"
                        class="form-control"
                        :placeholder="$t('bookingPickup.price')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('purchase_amount')"
                    >
                        {{ errors.first("purchase_amount") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("bookingPickup.qty")
                    }}</label>
                    <input
                        v-model.number="model.number_of_package"
                        type="number"
                        class="form-control"
                        :placeholder="$t('bookingPickup.numberOfPackage')"
                        @input="numberPackageChange"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('number_of_package')"
                    >
                        {{ errors.first("number_of_package") }}
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("bookingPickup.location")
                    }}</label>
                    <input
                        v-model.trim="model.destination"
                        type="text"
                        class="form-control"
                        :placeholder="$t('bookingPickup.price')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('destination')"
                    >
                        {{ errors.first("destination") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("bookingPickup.extraFee")
                    }}</label>
                    <input
                        v-model.number="model.extra_fee"
                        type="number"
                        class="form-control"
                        :placeholder="$t('bookingPickup.extraFee')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('extra_fee')"
                    >
                        {{ errors.first("extra_fee") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <TsImageInput
                        :file="model.package_photo"
                        @input="file => (model.package_photo = file)"
                        :height="130"
                        :width="200"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('package_photo')"
                    >
                        {{ errors.first("package_photo") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                    <ts-button
                        @click.prevent="$emit('cancel')"
                        :disabled="loading"
                        >{{ $t("cancel") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        :disabled="loading"
                        @click.prevent="onUpdate"
                        >{{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import { isEmpty } from "lodash";
export default {
    name: "formBookingPickup",
    data() {
        return {
            zoom: 7,
            center: { lat: 12.565679, lng: 104.990967 },
            marker: { lat: 0, lng: 0 },
            loading: false,
            errors: new Errors(),
            model: {
                booking_id: "",
								shop_id: undefined,
								service_type_id: undefined,
								pickup_request_id: undefined,
								driver_id: undefined,
                purchase_amount: "",
                // receiver_name: "",
                receiver_phone: "",
                destination: "",
                // destination_geo_location: "",
                package_type_id: null,
                number_of_package: 1,
                // packaging_fee: "",
                extra_fee: 0,
                // packaging_id: "",
                vehicle_type_id: "",
                fee_paid_by: 2,
                zone_id: null,
                package_photo: ""
            }
        };
    },
    computed: {
        ...mapState("driver/bookingPickup", ["formModels", "edit_data"]),
        isAdminData() {
            if (!this.isUpdate) {
                return true;
            } else {
                return this.edit_data.user_type == "admin";
            }
        },
        isUpdate() {
            return !isEmpty(this.edit_data);
        },
        feePerPage() {
            if (!this.formModels.shop) return 0;

            if (this.$whois.shop()) {
                return this.formModels.shop[0].fee_per_package;
            }
            if (this.model.shop_id) {
                return this.formModels.shop.find(
                    s => s.shop_id == this.model.shop_id
                ).fee_per_package;
            }

            return 0;
        },
        shopList() {
            if (!this.formModels.shop) return [];

            return this.formModels.shop.map(el => ({
                value: el.shop_id,
                label: el.shop_name
            }));
        },
        driverList() {
            if (!this.formModels.driver) return [];

            return this.formModels.driver.map(el => ({
                value: el.driver_id,
                label: el.driver_name,
                vehicle_type_id: el.vehicle_type_id
            }));
        },
        vehicleTypeList() {
            if (!this.formModels.vehicleType) return [];

            return this.formModels.vehicleType.map(el => ({
                value: el.vehicle_type_id,
                label: el.vehicle_types
            }));
        },
        packageTypeList() {
            if (!this.formModels.packageType) return [];

            return this.formModels.packageType.map(el => ({
                value: el.package_type_id,
                label: el.package_type_en
            }));
        },
        serviceType() {
            if (!this.formModels.serviceType) return [];

            return this.formModels.serviceType.map(el => ({
                value: el.service_type_id,
                label: el.service_type_name_en
            }));
        },
        zoneList() {
            if (!this.formModels.zone) return [];

            return this.formModels.zone.map(el => ({
                value: el.zone_id,
                label: el.zone_name
            }));
        }
    },
    created() {
        this.fetchFormView({ params: {}, index: undefined });
				this.setEditData()
    },
    methods: {
        ...mapActions("driver/bookingPickup", [
            "getFormViewData",
            "update",
            "upload"
        ]),
        fetchFormView({ params, index }) {
            this.loading = true;
            this.getFormViewData({ params: params, index: index })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onDriverChange(value, opt) {
            this.model.vehicle_type_id = opt.data.props.vehicle_type_id;
        },
        async onShopChange(value) {
            this.model.packaging_id = undefined;
            this.model.packaging_fee = "";
            this.loading = true;
            await this.getFormViewData({
                index: "exchangeRate,packaging",
                params: {
                    fnName: "exchangeRate,packaging",
                    shopId: value
                }
            })
            .finally(() => (this.loading = false));
        },
        onUpdate() {
            this.errors = new Errors();
            this.loading = true;
            this.update({
                id: this.model.booking_id,
                data: this.model
            })
                .then(async response => {
                    await this.uploadLogo(response.booking_id);
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        formData() {
            var formData = new FormData();
            for (var key in this.model) {
                if (key == "destination_geo_location") {
                    formData.append(
                        "destination_geo_location[lat]",
                        this.model[key].lat
                    );
                    formData.append(
                        "destination_geo_location[lng]",
                        this.model[key].lng
                    );
                } else {
                    formData.append(
                        key,
                        this.model[key] !== undefined ? this.model[key] : ""
                    );
                }
            }

            return formData;
        },
        async uploadLogo(bookingId) {
            if (
                this.model.package_photo instanceof File ||
                this.model.package_photo instanceof Blob
            ) {
                let formData = new FormData();
                formData.append("file", this.model.package_photo);
                formData.append("booking_id", bookingId);
                let response = await this.upload(formData);
                return response;
            }
            return "";
        },
        setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.model.booking_id = this.edit_data.booking_id;
								this.model.shop_id = this.edit_data.shop_id
								this.model.service_type_id = this.edit_data.service_type_id
								this.model.driver_id = this.edit_data.driver_id
                this.model.purchase_amount = this.edit_data.purchase_amount
                this.model.receiver_phone = this.edit_data.receiver_phone
                this.model.destination = this.edit_data.destination
                this.model.package_type_id = this.edit_data.package_type_id
                this.model.number_of_package = this.edit_data.number_of_package
                this.model.extra_fee = this.edit_data.extra_fee
                this.model.vehicle_type_id = this.edit_data.vehicle_type_id
                this.model.fee_paid_by = this.edit_data.fee_paid_by
                this.model.zone_id = this.edit_data.zone_id
                this.model.pickup_request_id = this.edit_data.pickup_request_id
                this.model.package_photo = this.edit_data.package_photo
            }
        },
        numberPackageChange() {
            this.model.packaging_fee =
                this.feePerPage * this.model.number_of_package;
        }
    }
};
</script>
